var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("text-editor", {
        ref: "text_editor",
        on: { "update:content": _vm.update },
      }),
      _c("pre", [_vm._v(_vm._s(_vm.content))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }